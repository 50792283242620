<template>
    <template v-if="dataGridControl">
        <div class="hstack min-h-unset mt-2" v-if="!hideHeader">
            <h6 class="mb-1 me-2">{{$t('Group By')}}</h6>
        </div>
        <div v-if="nodeData" 
            :class="{
                'hstack': compactMode, 
                'vstack': !compactMode, 
                'mt-2': hideHeader && !compactMode
            }">
            <div v-if="!compactMode" class="hstack">
                <div class="form-check form-switch">
                    <input class="form-check-input" v-model="nodeData.autoExpandOnFilter" type="checkbox" role="switch" :id="getId('auto-filter')">
                    <label class="form-check-label" for="getId('auto-filter')">{{$t('Auto expand rows on filter')}}</label>
                </div>
            </div>

            <div ref="groupByContainer" class="hstack o365-group-by-container"
                :class="compactMode ? 'w-100' : 'flex-wrap'">
                <div v-if="configurationsWithPlaceholders.length == 0" class="w-100" 
                    :class="compactMode ? 'p-2' : 'card p-3'">
                    <div class="hstack">
                        <i class="bi bi-box-arrow-in-down-left me-2"></i>
                        {{ $t('Drag a column here to group by') }}
                    </div>
                </div>
                <div class="o365-group-by-item" v-for="(level, index) in configurationsWithPlaceholders" :key="`${level.key}-${level.level}`"
                    :class="{'o365-group-by-hierarchy': level.type === 'hierarchy', 'o365-group-by-placeholder': level.isPlaceholder, 'duplicate': level.duplicate}">
                    <div class="o365-group-by-pill text-bg-primary rounded px-2 m-1">
                        <div v-if="level.isPlaceholder" class="hstack align-items-center h-100">
                            <span>{{level.name}}</span>
                        </div>
                        <div v-else class="hstack">
                            <i v-if="level.type === 'groupBy'" class="o365-group-by-handle bi bi-grip-vertical me-2"></i>
                            <span>{{level.ui.title}}</span>
                            <GroupScope :id="level.field">
                                <template #default="{col, filterItem}">
                                    <button v-if="col && col.sortable" type="button" class=" btn btn-sm btn-primary ms-auto" 
                                        @click="e => dataGridControl.header.setSort(col.colId, undefind, e.ctrlKey)">
                                        <i v-if="!col.sort" class="bi bi-arrow-down-up"></i>
                                        <i v-else-if="col.sort === 'asc'" class="bi bi-arrow-up"></i>
                                        <i v-else-if="col.sort === 'desc'" class="bi bi-arrow-down"></i>
                                        <small v-if="col.sortOrder">{{col.sortOrder}}</small>
                                    </button>
                                <ODropdown v-if="filterItem" placement="bottom-end" :popperOptions="filterPopperOptions">
                                    <template #default="scope">
                                        <button type="button" class="btn btn-sm btn-primary" :ref="scope.target"
                                            @click="scope.open()">
                                            <i class="bi bi-funnel-fill"></i>
                                        </button>
                                    </template>
                                    <template #dropdown="scope">
                                        <div @click="() => console.log(filterItem)" class="card shadow rounded-0 o365-filter-dropdown bg-light-subtle" :ref="scope.container">
                                            <div class="p-2" style="width: 400px">
                                                <component :is="filterItem.filterDropdown"
                                                    :filterItem="filterItem"
                                                    :filterObject="dataGridControl.dataObject.filterObject"
                                                    :dropdown="scope">
                                                </component>
                                            </div>
                                        </div>
                                    </template>
                                </ODropdown>
                                <button v-if="col" type="button" class=" btn btn-sm btn-primary" @click="col.hide = !col.hide"
                                    :title="col._hide ? $t('Show column') : $t('Hide column')">
                                    <i v-if="col._hide" class="bi bi-eye-fill"></i>
                                    <i v-else class="bi bi-eye-slash-fill"></i>
                                </button>
                                </template>
                            </GroupScope>  

                            <button v-if="level.type === 'groupBy' && level.pathField" class="btn btn-sm btn-primary ms-auto" @click="dataGridControl.nodeData.togglePathGroupBy(level)"
                                :title="!level.pathMode ? $t('Group by path') : $t('Group by field')">
                                <i class="bi" :class="!level.pathMode ? 'bi-list' : 'bi-list-nested'"></i>
                            </button>
                            <button v-if="level.type === 'groupBy'" class="btn btn-sm btn-primary ms-auto" @click="dataGridControl.nodeData.removeLevel(level.level)"
                                :title="$t('Remove group by')">
                                <i class="bi bi-x-lg"></i>
                            </button>
                            <button v-else class="btn btn-sm btn-primary ms-auto" @click="dataGridControl.nodeData.toggleLevel(level.level)"
                                :title="level.disabled ? $t('Enable hierarchy level') : $t('Disable hierarchy level')">
                                <i class="bi" :class="level.disabled ? 'bi-list' : 'bi-list-nested'"></i>
                            </button>
                        </div>
                    </div>
                    <i v-if="index < configurationsWithPlaceholders.length - 1" class="bi bi-chevron-double-right mx-2 o365-group-by-seperator"></i>
                </div>
            </div>
            <template v-if="!compactMode && dataGridControl.nodeData.props.userAggregates">
                <b class="mt-2">{{ $t('Aggregates') }}</b>
                <div class="hstack flex-wrap o365-aggregates-container" ref="aggregatesContainer">
                    <div v-if="dataGridControl.nodeData.aggregates.length == 0" class="card w-100 p-3">
                        <div class="hstack">
                            <i class="bi bi-box-arrow-in-down-left me-2"></i>
                            {{ $t('Drag a column here to aggregate') }}
                        </div>
                    </div>
                    <div v-for="aggregate in dataGridControl.nodeData.aggregates" class="o365-group-by-pill text-bg-primary rounded px-2 m-1">
                        <div class="hstack">
                            <span>[{{ aggregate.caption }}]</span>
                            <OSelect :modelValue="aggregate.aggregate" minWidth="100" @selected="(value) => dataGridControl.nodeData.updateAggregate(aggregate, value)">
                                <template #target="scope">
                                    <button :ref="scope.target" class="btn btn-sm btn-primary ms-2 w-100" @click="scope.open">
                                        {{ aggregate.aggregate }}
                                    </button>
                                </template>
                                <template #default>
                                    <option value="COUNT">{{ $t('COUNT') }}</option>
                                    <template v-if="aggregate.type === 'number'">
                                        <option value="SUM">{{ $t('SUM') }}</option>
                                        <option value="AVG">{{ $t('AVG') }}</option>
                                    </template>
                                    <option value="MAX">{{ $t('MAX') }}</option>
                                    <option value="MIN">{{ $t('MIN') }}</option>
                                </template>
                            </OSelect>
                            <button class="btn btn-sm btn-primary ms-auto" @click="dataGridControl.nodeData.removeAggregate(aggregate.name)">
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </template>
    <template v-else>
        <span class="text-danger">{{ $t('This component needs to be inside a grid')}}</span>
    </template>
</template>
<script lang="ts">
import { OFilterDropdown } from 'o365-filter-components'

export default {
    components: { OFilterDropdown },
}
</script>
<script setup lang="ts">
import type { Ref } from 'vue';
import type { DataGridControl as DataGrid } from 'o365-datagrid';
import { OSelect } from 'o365-ui-components';
import { InjectionKeys } from 'o365-utils';
import { GroupByContainerControl } from './DataGrid.NodeData.ts'
import { ref, reactive, inject, computed, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps<{
    hideHeader?: boolean,
    compactMode?: boolean,
}>();

const dataGridControl: Ref<DataGrid|null> = inject(InjectionKeys.dataGridControlKey, ref(null));
const groupByContainerControl = reactive(new GroupByContainerControl(dataGridControl.value));

const groupByContainer = ref<HTMLElement | null>(null);
const aggregatesContainer = ref<HTMLElement | null>(null);
const nodeData = computed(() => dataGridControl.value?.dataObject?.hasNodeData ? dataGridControl.value?.dataObject?.nodeData : undefined);

const filterPopperOptions = [{
    name: 'offset',
    options: {
        offset: [-1, -1],
    },
}];


const configurationsWithPlaceholders = computed(() => {
    console.log('config computed');
    if (groupByContainerControl.placeholder) {
        const configurations = [...nodeData.value.configurations]
        configurations.splice(groupByContainerControl.placeholderPosition, 0, groupByContainerControl.placeholder);
        return configurations
    } else {
        return nodeData.value.configurations;
    }
});
const compId = crypto.randomUUID();

function getId(pId: string) {
    return `${pId}-${compId}`;
}

function getColumn(pId: string) {
    return dataGridControl.value.dataColumns.getColumn(pId);
}

function getFilterItem(pId: string) {
    return dataGridControl.value.dataObject.filterObject?.getItem(pId);
}

function GroupScope(props: { id: string }, ctx: { slots: { default: (pScope: { col: any, filterItem: any }) => void } }) {
    const col = getColumn(props.id);
    const filterItem = getFilterItem(props.id);
    return ctx.slots.default({ col: col, filterItem: filterItem });
}
GroupScope.props = ['id'];

onMounted(() => {
    if (groupByContainer.value) {
        groupByContainerControl.initializeGroupByContainer(groupByContainer.value);
    }
    if (aggregatesContainer.value) {
        groupByContainerControl.initializeAggregatesContainer(aggregatesContainer.value);
    }
});

onBeforeUnmount(() => {
    groupByContainerControl.clearContainers();
});
</script>

<style scoped>
.o365-group-by-handle {
    cursor: grab;
}

.o365-group-by-item {
    display: flex;
    align-items: center;
}

.o365-group-by-pill {
    min-width: 180px;
}

.o365-group-by-container {
    overflow-x: auto;
}
.o365-group-by-container .o365-group-by-item:last-child .o365-group-by-seperator {
    display: none!important;
}
.sortable-chosen .o365-group-by-seperator {
    display: none!important;
}

.o365-group-by-ghost .o365-group-by-pill {
    outline: rgb(var(--bs-info-rgb)) solid
}

.o365-group-by-placeholder .o365-group-by-pill {
    height: 31px;
    text-align: center;
    background-color: rgba(var(--bs-primary-rgb), .5)!important;
}

.o365-group-by-placeholder.duplicate .o365-group-by-pill {
    background-color: rgba(var(--bs-danger-rgb), .5)!important;
}
</style>